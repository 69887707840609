export default {
    'Contact': { path: "@/components/modal/HWP_Modal_Contacts", name: 'HWP_Modal_Contacts' },
    'Normative': { path: "@/components/modal/HWP_Modal_FlatIndicator", name: 'HWP_Modal_FlatIndicator' },
    'LegacyNormative': { path: "@/components/modal/HWP_Modal_LegacyFlatIndicator", name: 'HWP_Modal_LegacyFlatIndicator' },
    'TariffGroup': { path: "@/components/modal/HWP_Modal_TariffGroups", name: 'HWP_Modal_TariffGroups' },
    'TariffLegacyGroup': { path: "@/components/modal/HWP_Modal_TariffLegacyGroups.vue", name: 'HWP_Modal_TariffLegacyGroups' },
    'Family': { path: "@/components/modal/HWP_Modal_Family", name: 'HWP_Modal_Family' },
    'City': { path: "@/components/modal/HWP_Modal_City", name: 'HWP_Modal_City' },
    'AmalgamatedHromada': { path: "@/components/modal/HWP_Modal_AmalgamatedHromada", name: 'HWP_Modal_AmalgamatedHromada' },
    'CityArea': { path: "@/components/modal/HWP_Modal_CityArea", name: 'HWP_Modal_CityArea' },
    'Street': { path: "@/components/modal/HWP_Modal_Street", name: 'HWP_Modal_Street' },
    'Building': { path: "@/components/modal/HWP_Modal_Building", name: 'HWP_Modal_Building' },
    'BuildingIndicator': { path: "@/components/modal/HWP_Modal_BuildingIdenticator", name: 'HWP_Modal_BuildingIdenticator' },
    'Flat': { path: "@/components/modal/HWP_Modal_Flat", name: 'HWP_Modal_Flat' },
    'LegacyFlat': { path: "@/components/modal/HWL_Modal_FlatContractorCreate.vue", name: 'HWL_Modal_FlatContractorCreate' },
    'Service': { path: "@/components/modal/HWP_Modal_Service", name: 'HWP_Modal_Service' },
    'TariffOrganization': { path: "@/components/modal/HWP_Modal_TariffByOrganization", name: 'HWP_Modal_TariffByOrganization' },
    'TariffBuilding': { path: "@/components/modal/HWP_Modal_TariffByBuilding", name: 'HWP_Modal_TariffByBuilding' },
    'FlatService': { path: "@/components/modal/HWP_Modal_FlatService", name: 'HWP_Modal_FlatService' },
    'FlatServiceLegacy': { path: "@/components/modal/HWP_Modal_FlatServiceLegacy.vue", name: 'HWP_Modal_FlatServiceLegacy' },
    'FlatSearch': { path: "@/components/modal/HWP_Modal_FlatSearch", name: 'HWP_Modal_FlatSearch' },
    'FlatCourtCase': { path: "@/components/modal/HWP_Modal_Flat_CourtCase", name: 'HWP_Modal_Flat_CourtCase' },
    'BankAccount': { path: "@/components/modal/HWP_Modal_BankAccount", name: 'HWP_Modal_BankAccount' },
    'Bank': { path: "@/components/modal/HWP_Modal_Bank", name: 'HWP_Modal_Bank' },
    'FlatIndicatorType': { path: "@/components/modal/HWP_Modal_FlatIndicatorType", name: 'HWP_Modal_FlatIndicatorType' },
    'UnitType': { path: "@/components/modal/HWP_Modal_Unit_Type", name: 'HWP_Modal_Unit_Type' },
    'Position': { path: "@/components/modal/HWP_Modal_Position", name: "HWP_Modal_Position" },
    'AbsentPersons': { path: "@/components/modal/HWP_Modal_AbsentPerson", name: "HWP_Modal_AbsentPerson" },
    'NaturalPerson': { path: "@/components/modal/HWP_Modal_NaturalPeople", name: "HWP_Modal_NaturalPeople" },
    'ResponsePerson': { path: "@/components/modal/HWP_Modal_ResponsePerson", name: "HWP_Modal_ResponsePerson" },
    'OrganizationContact': { path: "@/components/modal/HWP_Modal_OrganizationContact", name: "HWP_Modal_OrganizationContact" },
    'AccountPaymentView': { path: "@/components/modal/HWP_Modal_AccountPay.vue", name: 'HWP_Modal_AccountPay' },
    'CounterModel': { path: "@/components/modal/HWP_Modal_CounterModal.vue", name: 'HWP_Modal_CounterModal' },
    'FlatCounter': { path: "@/components/modal/HWP_Modal_FlatCounter.vue", name: 'HWP_Modal_FlatCounter' },
    'DispatcherCall': { path: "@/components/modal/HWP_Modal_DispatcherCall.vue", name: 'HWP_Modal_DispatcherCall' },
    'SmsSendConfig': { path: "@/components/modal/HWP_Modal_SmsSendConfig.vue", name: 'HWP_Modal_SmsSendConfig' },
    'SmsSendTemplate': { path: "@/components/modal/HWP_Modal_SmsSendTemplate.vue", name: 'HWP_Modal_SmsSendTemplate' },
    'CounterTransferCoefficient': { path: "@/components/modal/HWP_Modal_CounterTransferCoefficient.vue", name: 'HWP_Modal_CounterTransferCoefficient' },
    'PenaltyTariff': { path: "@/components/modal/HWP_Modal_PenaltyTariff.vue", name: 'HWP_Modal_PenaltyTariff' },
    'RestructuringAgreement': { path: "@/components/modal/HWP_Modal_RestructuringAgreement.vue", name: 'HWP_Modal_RestructuringAgreement' },
    'FlatCounterLegacy': { path: "@/components/modal/HWP_Modal_FlatCounterLegacy.vue", name: 'HWP_Modal_FlatCounterLegacy' },
    'FlatCounterReading': { path: "@/components/modal/HWP_Modal_FlatCounterReading.vue", name: 'HWP_Modal_FlatCounterReading' },
    'FlatCounterLegacyReading': { path: "@/components/modal/HWP_Modal_FlatCounterLegacyReading.vue", name: 'HWP_Modal_FlatCounterLegacyReading' },
    'FlatCounterAvg': { path: "@/components/modal/HWP_Modal_FlatCounterAvg.vue", name: 'HWP_Modal_FlatCounterAvg' },
    'FlatCounterFmc': { path: "@/components/modal/HWP_Modal_FlatCounterFmc.vue", name: 'HWP_Modal_FlatCounterFmc' },
    'Regular': { path: "@/components/modal/HWP_Modal_Normative.vue", name: 'HWP_Modal_Normative' },
    'RegularOrganization': { path: "@/components/modal/HWP_Modal_RegularByOrganization.vue", name: 'HWP_Modal_RegularByOrganization' },
    'FlatDocument': { path: "@components/modal/HWP_Modal_FlatDocument.vue", name: 'HWP_Modal_FlatDocument' },
    'Recalculation': { path: "@components/modal/HWP_Modal_Recalculation.vue", name: 'HWP_Modal_Recalculation' },
    'PrivilegeRecalculation': { path: "@components/modal/HWP_Modal_PrivilegeRecalculation.vue", name: 'HWP_Modal_PrivilegeRecalculation' },
    'BuildingCounter': { path: "@components/modal/HWP_Modal_BuildingCounter.vue", name: 'HWP_Modal_BuildingCounter' },
    'BuildingCounterReading': { path: "@components/modal/HWP_Modal_BuildingCounterReading.vue", name: 'HWP_Modal_BuildingCounterReading' },
    'Vote': { path: "@components/modal/HWP_Modal_Vote.vue", name: 'HWP_Modal_Vote' },
    'TariffGroupType': { path: "@components/modal/HWP_Modal_TariffGroupType.vue", name: 'HWP_Modal_TariffGroupType' },
    'Contract': { path: "@components/modal/HWP_Modal_FlatContract.vue", name: 'HWP_Modal_FlatContract' },
    'ContractLegacy': { path: "@components/modal/HWP_Modal_FlatLegacyContract.vue", name: 'HWP_Modal_FlatLegacyContract' },
    'Checker': { path: "@components/modal/HWP_Modal_Checker.vue", name: 'HWP_Modal_Checker' },
    'FlatBudget': { path: "@components/modal/HWP_Modal_FlatBudget.vue", name: 'HWP_Modal_FlatBudget' },
    'UserMessageHistory': { path: "@components/modal/HWP_Modal_UserMessageHistory.vue", name: 'HWP_Modal_UserMessageHistory' },
    'SpreedSheet': { path: "@components/modal/HWP_Modal_SpreedSheetViewer.vue", name: 'HWP_Modal_SpreedSheetViewer' },
    'FlatNote': { path: "@components/modal/HWP_Modal_Flat_Note.vue", name: 'HWP_Modal_Flat_Note' },
    'RegistrationOfChanges': { path: "@components/modal/HWP_Modal_RegistrationOfChanges.vue", name: 'HWP_Modal_RegistrationOfChanges' },
    'FlatCounterOperation': { path: "@components/modal/HWP_Modal_FlatCounterOperation.vue", name: 'HWP_Modal_FlatCounterOperation' },
    'FlatCounterSubAccount': { path: "@components/modal/HWP_Modal_FlatCounterSubAccount.vue", name: 'HWP_Modal_FlatCounterSubAccount' },
    'ApplianceModel': { path: "@components/modal/HWP_Modal_ApplianceModel.vue", name: 'HWP_Modal_ApplianceModel' },
    'ApplianceByFlat': { path: "@components/modal/HWP_Modal_ApplianceByFlat.vue", name: 'HWP_Modal_ApplianceByFlat' },
    'ApplianceOperation': { path: "@components/modal/HWP_Modal_ApplianceOperation.vue", name: 'HWP_Modal_ApplianceOperation' },
    'CounterContractor': { path: "@components/modal/HWP_Modal_CounterContractor.vue", name: 'HWP_Modal_CounterContractor' },
    'CounterInstallationPlace': { path: "@components/modal/HWP_Modal_CounterInstallationPlace.vue", name: 'HWP_Modal_CounterInstallationPlace' },
    'OneTimeCharge': { path: "@components/modal/HWP_One_Time_Charge.vue", name: 'HWP_One_Time_Charge' },
    'OneTimeChargeLegacy': { path: "@components/modal/HWP_One_Time_Charge_Legacy.vue", name: 'HWP_One_Time_Charge_Legacy' },
    'CloseSwapDebt': { path: "@components/modal/HWP_Close_Swap_Debt.vue", name: 'HWP_Close_Swap_Debt' },
    'CloseSwapDebtLegacy': { path: "@components/modal/HWP_Close_Swap_Debt_Legacy.vue", name: 'HWP_Close_Swap_Debt_Legacy' },
    'PrivilegeCategory': { path: "@components/modal/HWP_Modal_PrivilegeCategory.vue", name: 'HWP_Modal_PrivilegeCategory' },
    'PrivilegeOwner': { path: "@components/modal/HWP_Modal_PrivilegeOwner.vue", name: 'HWP_Modal_PrivilegeOwner' },
    'PrivilegePersons': { path: "@components/modal/HWP_Modal_PrivilegePerson.vue", name: 'HWP_Modal_PrivilegePerson' },
    'BasesByService': { path: "@components/modal/HWP_Modal_BasesByService.vue", name: 'HWP_Modal_BasesByService' },
    'PaySystem': { path: "@components/modal/HWP_Modal_PaySystem.vue", name: 'HWP_Modal_PaySystem' },
    'PaymentsByPriority': { path: "@components/modal/HWP_Modal_PaymentsByPriority.vue", name: 'HWP_Modal_PaymentsByPriority' },
    'OneTimeChargeByFlat': { path: "@components/modal/HWP_Modal_OneTime.vue", name: 'HWP_Modal_OneTime' },
    'OneTimeChargeByFlatLegacy': { path: "@components/modal/HWP_Modal_OneTime_Legacy.vue", name: 'HWP_Modal_OneTime_Legacy' },
    'CloseSwapDebtByFlat': { path: "@components/modal/HWP_Modal_CloseSwapDebtByFlat.vue", name: 'HWP_Modal_CloseSwapDebtByFlat' },
    'ChartOfAccount': { path: "@components/modal/HWP_Chart_Of_Account.vue", name: 'HWP_Chart_Of_Account' },
    'FillAddress': { path: "@components/modal/HWP_Fill_Address.vue", name: 'HWP_Fill_Address' },
    'MonetaryDebt': { path: "@components/modal/HWP_Modal_MonetaryDebt.vue", name: 'HWP_Modal_MonetaryDebt' },
    'MonetaryPay': { path: "@components/modal/HWP_Modal_MonetaryPay.vue", name: 'HWP_Modal_MonetaryPay' },
    'MonetaryImport': { path: "@components/modal/HWP_Modal_MonetaryFileImport.vue", name: 'HWP_Modal_MonetaryFileImport' },
    'DocumentGroupType': { path: "@components/modal/HWP_ModalDocumentGroupType.vue", name: 'HWP_ModalDocumentGroupType' },
    'AppealReceiverGroup': { path: "@components/modal/HWP_Modal_AppealReceiverGroup.vue", name: 'HWP_Modal_AppealReceiverGroup' },
    'RemovalItems': { path: "@components/modal/HWP_Modal_Removal.vue", name: 'HWP_Modal_Removal' },
    'RemovalItem': { path: "@components/modal/HWP_Modal_RemovalItem.vue", name: 'HWP_Modal_RemovalItem' },
    'OrganizationDocuments': { path: "@components/modal/HWP_Modal_OrganizationDocument.vue", name: 'HWP_Modal_OrganizationDocument' },
    'DocumentType': { path: "@components/modal/HWP_ModalDocumentType.vue", name: 'HWP_ModalDocumentType' },
    'News': { path: "@components/modal/HWP_ModalNews.vue", name: 'HWP_ModalNews' },
    'Notification': { path: "@components/modal/HWP_ModalNotification.vue", name: 'HWP_ModalNotification' },
    'Gallery': { path: "@components/modal/HWP_ModalGallery.vue", name: 'HWP_ModalGallery' },
    'MediaSend': { path: "@components/modal/HWP_Modal_MediaSend.vue", name: 'HWP_Modal_MediaSend' },
    'LegacyDocumentsSettings': { path: "@components/modal/HWP_Modal_LegacyDocumentSetting.vue", name: 'HWP_Modal_LegacyDocumentSetting' },
    'WAREHOUSE_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Warehouse.vue', name: 'HWP_Modal_Accounting_Warehouse'},
    'CONTRACTOR_CONTACT_PERSON_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_ContractorContactPerson.vue', name: 'HWP_Modal_Accounting_ContractorContactPerson'},
    'CONTRACTOR_RESPONSE_PERSON_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_ContractorResponsePerson.vue', name: 'HWP_Modal_Accounting_ContractorResponsePerson'},
    'CONTRACTOR_BANK_ACCOUNT_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_ContractorBankAccount.vue', name: 'HWP_Modal_Accounting_ContractorBankAccount'},
    'NOMENCLATURE_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Nomenclature_Element.vue', name: 'HWP_Modal_Accounting_Nomenclature_Element'},
    'DISPATCHER_PROBLEM_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Dispatcher_Problem_Element.vue', name: 'HWP_Modal_Accounting_Dispatcher_Problem_Element'},
    'CURRENCY_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Currency.vue', name: 'HWP_Modal_Accounting_Currency'},
    'HOME_ACCOUNTING_PAYMENTS_SETTINGS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_House_Accounting_Payments.vue', name: 'HWP_Modal_Accounting_House_Accounting_Payments'},
    'HOME_ACCOUNTING_CHARGE_SETTINGS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_House_Accounting_Charge.vue', name: 'HWP_Modal_Accounting_House_Accounting_Charge'},
    'AMORTISATION_CALC_WAY_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_AmortisationCalcWay.vue', name: 'HWP_Modal_Accounting_AmortisationCalcWay'},
    'CONTRACTOR_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Contractor.vue', name: 'HWP_Modal_Accounting_Contractor'},
    'POSITION_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Position.vue', name: 'HWP_Modal_Accounting_Position'},
    'TAX_INSPECTION_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_TaxInspection.vue', name: 'HWP_Modal_Accounting_TaxInspection'},
    'NATURAL_PERSON_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_NaturalPerson.vue', name: 'HWP_Modal_Accounting_NaturalPerson'},
    'SUBDIVISION_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Subdivision.vue', name: 'HWP_Modal_Accounting_Subdivision'},
    'UNIT_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Unit.vue', name: 'HWP_Modal_Accounting_Unit'},
    'PERSONAL_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Personal.vue', name: 'HWP_Modal_Accounting_Personal'},
    'CHART_OF_ACCOUNTS_ELEMENTS': { path: '@components/modal/HWP_Chart_Of_Account.vue', name: 'HWP_Chart_Of_Account'},
    'CASH_FLOW_ITEM_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Cash_Flow_Item.vue', name: 'HWP_Modal_Accounting_Cash_Flow_Item'},
    'RELEASE_REASON_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Release_Reason.vue', name: 'HWP_Modal_Accounting_Release_Reason'},
    'BANK_ACCOUNT_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_BankAccount.vue', name: 'HWP_Modal_Accounting_BankAccount'},
    'BIOLOGICAL_ASSETS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Biological_Assets.vue', name: 'HWP_Modal_Accounting_Biological_Assets'},
    'COST_ITEMS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Cost_Items.vue', name: 'HWP_Modal_Accounting_Cost_Items'},
    'FIXED_ASSETS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Fixed_Assets.vue', name: 'HWP_Modal_Accounting_Fixed_Assets'},
    'INTANGIBLE_ASSETS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Intangible_Assets.vue', name: 'HWP_Modal_Accounting_Intangible_Assets'},
    'WORK_SCHEDULE_ELEMENTS': { path: '@components/modal/HWP_Modal_Work_Schedule.vue', name: 'HWP_Modal_Work_Schedule'},
    'ACCRUAL_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Accrual.vue', name: 'HWP_Modal_Accounting_Accrual'},
    'TIMESHEET_LETTER_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_TimesheetLetter.vue', name: 'HWP_Modal_Accounting_TimesheetLetter'},
    'INCOME_TYPE_PIX_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_IncomeTypePIX.vue', name: 'HWP_Modal_Accounting_IncomeTypePIX'},
    'INCOME_ITEMS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_IncomeItems.vue', name: 'HWP_Modal_Accounting_IncomeItems'},
    'NOMENCLATURE_GROUPS_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_NomenclatureGroups.vue', name: 'HWP_Modal_Accounting_NomenclatureGroups'},
    'TAXES_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Taxes.vue', name: 'HWP_Modal_Accounting_Taxes'},
    'MIN_AMOUNT_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Min_Amount.vue', name: 'HWP_Modal_Accounting_Min_Amount'},
    'INFLATION_INDEX_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Inflation_Index.vue', name: 'HWP_Modal_Accounting_Inflation_Index'},
    'TAX_TARIFF_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_Tax_Tariff.vue', name: 'HWP_Modal_Accounting_Tax_Tariff'},
    'CONTRACTOR_CONTRACT_ELEMENTS': { path: '@components/modal/HWP_Modal_Accounting_ContractorContract.vue', name: 'HWP_Modal_Accounting_ContractorContract'},
    'DOCUMENT_BANK_INCOME': { path: '@components/modal/HWP_Modal_Accounting_Document_BankIncome.vue', name: 'HWP_Modal_Accounting_Document_BankIncome'},
    'DOCUMENT_BANK_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_Document_BankOutcome.vue', name: 'HWP_Modal_Accounting_Document_BankOutcome'},
    'DOCUMENT_CASH_ORDER_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_Document_CashOrderOutcome.vue', name: 'HWP_Modal_Accounting_Document_CashOrderOutcome'},
    'DOCUMENT_CASH_ORDER_INCOME': { path: '@components/modal/HWP_Modal_Accounting_Document_CashOrderIncome.vue', name: 'HWP_Modal_Accounting_Document_CashOrderIncome'},
    'DOCUMENT_PERSON_TIMESHEET': { path: '@components/modal/HWP_Modal_Personal_Timesheet.vue', name: 'HWP_Modal_Personal_Timesheet'},
    'DOCUMENT_WORK_TRANSFER': { path: '@components/modal/HWP_Modal_Work_Transfer.vue', name: 'HWP_Modal_Work_Transfer'},
    'DOCUMENT_EMPLOYMENT': { path: '@components/modal/HWP_Modal_Accounting_Employment.vue', name: 'HWP_Modal_Accounting_Employment'},
    'DOCUMENT_CONTRACT_BY_CLN': { path: '@components/modal/HWP_Modal_Accounting_Contract_By_Cln.vue', name: 'HWP_Modal_Accounting_Contract_By_Cln'},
    'DOCUMENT_SOCIAL_PRIVILEGE': { path: '@components/modal/HWP_Modal_Accounting_Social_Privilege.vue', name: 'HWP_Modal_Accounting_Social_Privilege'},
    'DOCUMENT_SALARY_CHARGE': { path: '@components/modal/HWP_Modal_Accounting_Salary_Charge.vue', name: 'HWP_Modal_Accounting_Salary_Charge'},
    'DOCUMENT_SALARY_TO_PAY': { path: '@components/modal/HWP_Modal_Accounting_Salary_To_Pay.vue', name: 'HWP_Modal_Accounting_Salary_To_Pay'},
    'DOCUMENT_HOSPITAL_CHARGE': { path: '@components/modal/HWP_Modal_Accounting_Hospital.vue', name: 'HWP_Modal_Accounting_Hospital'},
    'DOCUMENT_VOCATION_CHARGE': { path: '@components/modal/HWP_Modal_Accounting_Vocation.vue', name: 'HWP_Modal_Accounting_Vocation'},
    'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_ActWorkPerformedOutcome.vue', name: 'HWP_Modal_Accounting_ActWorkPerformedOutcome'},
    'DOCUMENT_ACT_WORK_PERFORMED_INCOME': { path: '@components/modal/HWP_Modal_Accounting_ActWorkPerformedIncome.vue', name: 'HWP_Modal_Accounting_ActWorkPerformedIncome'},
    'DOCUMENT_INVOICE_WAREHOUSE_INCOME': { path: '@components/modal/HWP_Modal_Accounting_InvoiceWarehouseIncome.vue', name: 'HWP_Modal_Accounting_InvoiceWarehouseIncome'},
    'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_InvoiceWarehouseOutcome.vue', name: 'HWP_Modal_Accounting_InvoiceWarehouseOutcome'},
    'DOCUMENT_BILL_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_BillOutcome.vue', name: 'HWP_Modal_Accounting_BillOutcome'},
    'DOCUMENT_WAREHOUSE_INVENTORY_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_WarehouseInventoryOutcome.vue', name: 'HWP_Modal_Accounting_WarehouseInventoryOutcome'},
    'DOCUMENT_ADVANCE_REPORT': { path: '@components/modal/HWP_Modal_Accounting_AdvanceReport.vue', name: 'HWP_Modal_Accounting_AdvanceReport'},
    'DOCUMENT_COMMISSIONING': { path: '@components/modal/HWP_Modal_Accounting_Document_CommissioningAssets.vue', name: 'HWP_Modal_Accounting_Document_CommissioningAssets'},
    'DOCUMENT_LOW_COST_COMMISSIONING': { path: '@components/modal/HWP_Modal_Accounting_Document_LowCostCommissioning.vue', name: 'HWP_Modal_Accounting_Document_LowCostCommissioning'},
    'DOCUMENT_ACCOUNTING_CLOSE_FINANCIAL_MONTH': { path: '@components/modal/HWP_Modal_Accounting_Document_CloseFinancialMonth.vue', name: 'HWP_Modal_Accounting_Document_CloseFinancialMonth'},
    'DOCUMENT_ACCOUNTING_CLOSE_HOME_ACCOUNTING': { path: '@components/modal/HWP_Modal_Accounting_Document_CloseHomeAccounting.vue', name: 'HWP_Modal_Accounting_Document_CloseHomeAccounting'},
    'DOCUMENT_ENTERING_BALANCES': { path: '@components/modal/HWP_Modal_Accounting_Document_EnteringBalances.vue', name: 'HWP_Modal_Accounting_Document_EnteringBalances'},
    'DOCUMENT_MANUAL_OPERATION': { path: '@components/modal/HWP_Modal_Accounting_Document_ManualOperation.vue', name: 'HWP_Modal_Accounting_Document_ManualOperation'},
    'DOCUMENT_TAX_BILL_INCOME': { path: '@components/modal/HWP_Modal_Accounting_Document_TaxBillIncome.vue', name: 'HWP_Modal_Accounting_Document_TaxBillIncome'},
    'DOCUMENT_TAX_BILL_OUTCOME': { path: '@components/modal/HWP_Modal_Accounting_Document_TaxBillOutcome.vue', name: 'HWP_Modal_Accounting_Document_TaxBillOutcome'},
    'DOCUMENT_RELEASE_WORK': { path: '@components/modal/HWP_Modal_Accounting_Document_ReleaseWork.vue', name: 'HWP_Modal_Accounting_Document_ReleaseWork'},
}